<template>
    <div>
        <alert-modal
            v-if="isAlertModal"
            :alertText="'입력하신 정보로 가입된 정보가 없습니다.'"
            @closeModal="closeAlertModal()"
        />
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                </a>
                <h2>아이디 찾기</h2>
                <div></div>
            </div>
        </div>
        <section class="login_main find_pw">
            <div class="login_main_box">
                <h1 class="find_id_title">아이디 찾기</h1>
                <p class="find_id_desc">아이디 찾기를 위해 본인인증을 해주세요.</p>
                <button v-if="!isCheckNice" class="find_id_certification" @click="certification">본인 인증</button>
                <button v-if="isCheckNice" class="find_id_certification done">
                    인증 완료
                    <span class="align_center">
                        <img class="done_img" src="/media/images/done.png" />
                    </span>
                </button>
                <button class="next" :disabled="!isCheckNice" @click="selectEmail">다음</button>
            </div>

            <input type="hidden" id="birthDate" value="" />
            <input type="hidden" id="encryptString" value="" />
        </section>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AlertModal from '@/components/modal/Space/AlertModal.vue';
import { getUserCheck, findOldSocialInfoUseMobileNo } from '@/service/api/niceApi';
export default defineComponent({
  components: {
    'alert-modal': AlertModal
  },
  data () {
    return {
      name: '',
      phone: '',
      isCheckNice: false,
      isAlertModal: false,
      mailList: []
    };
  },
  mounted () {
    this.setInputEvent();
  },
  methods: {
    setInputEvent () {
      const input = document.querySelector('#birthDate');
      const encryptString = document.querySelector('#encryptString');
      input.addEventListener('change', $event => {
        if (encryptString.value && $event.target.value) {
          //
          findOldSocialInfoUseMobileNo(encryptString.value)
            .then(res => {
              const { resultCd, result } = res.data;
              if (resultCd === '0000') {
                this.isCheckNice = true;
                this.mailList = result;
              } else {
                this.isCheckNice = false;
                this.mailList = [];
              }
            })
            .catch(() => {
              this.isCheckNice = false;
              this.mailList = [];
            })
            .finally(() => {
              input.value = '';
              encryptString.value = '';
            });
        }
      });
    },
    closeAlertModal () {
      this.isAlertModal = false;
    },
    makeFormData (tokenVersionId, encData, integrityValue) {
      const form = document.createElement('form');

      form.target = 'popup';
      form.method = 'Post';
      form.setAttribute('action', process.env.VUE_APP_NICE_URL);

      const inputService = document.createElement('input');
      inputService.setAttribute('type', 'hidden');
      inputService.setAttribute('value', 'service');
      inputService.setAttribute('id', 'm');
      inputService.setAttribute('name', 'm');

      form.appendChild(inputService);

      const inputToken = document.createElement('input');
      inputToken.setAttribute('type', 'hidden');
      inputToken.setAttribute('value', tokenVersionId);
      inputToken.setAttribute('id', 'token_version_id');
      inputToken.setAttribute('name', 'token_version_id');

      form.appendChild(inputToken);

      const inputEncData = document.createElement('input');
      inputEncData.setAttribute('type', 'hidden');
      inputEncData.setAttribute('value', encData);
      inputEncData.setAttribute('id', 'enc_data');
      inputEncData.setAttribute('name', 'enc_data');

      form.appendChild(inputEncData);

      const inputIv = document.createElement('input');
      inputIv.setAttribute('type', 'hidden');
      inputIv.setAttribute('value', integrityValue);
      inputIv.setAttribute('id', 'integrity_value');
      inputIv.setAttribute('name', 'integrity_value');

      form.appendChild(inputIv);

      document.body.appendChild(form);

      form.submit();

      document.body.removeChild(form);
    },
    async certification () {
      window.open('', 'popup', 'width=545, height=875, toolbar=yes, location=no');

      getUserCheck()
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const { tokenVersionId, encData, integrityValue } = result;
            this.makeFormData(tokenVersionId, encData, integrityValue);
          }
        })
        .catch(error => {
          console.error('getUserCheck error  : ', error);
        });
    },
    selectEmail () {
      this.$router.push({ name: 'FindIdFinished', params: { mailList: this.mailList } });
    },
    clickPre () {
      history.back();
    }
  },
  watch: {}
});
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped>
.find_id_title {
  margin-bottom: 0;
}

.find_id_certification {
  width: 540px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 0;
  background: transparent;
  border: 1px solid var(--whitefff);
  line-height: 16px;
  height: auto;
  margin-bottom: 0;
}

.done {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: initial;
}

.done_img {
  width: 13px;
  height: 10px;
  margin-left: 7px;
}

.find_id_desc {
  color: var(--whitefff);
  font-size: 28px;
  line-height: 52px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
}

.next:disabled {
  opacity: 0.5;
}

@media screen and (max-width: 500px) {
  .find_id_desc {
    text-align: left;
    font-size: 20px;
  }

  .find_id_certification {
    width: 100%;
    line-height: 1;
    padding: 17px 0;
  }

  .login_main_box button.next {
    margin-top: 20px;
    border: 1px solid var(--secondary);
    height: auto;
    line-height: 1;
    padding: 17px 0;
  }
}
</style>
