import axios from 'axios';

/**
 * @description 나이스 tokenVersionId, encData
 * @returns Promise
 */
const getUserCheck = async () => {
  return await axios.post(`/api/nice/v2/userCheck`);
};

/**
 * @description nice 통합인증 프로필 정보 가져오기
 * @param {*} encryptString
 * @returns Promise
 */
const niceDecrptVal = async (encryptString = '') => {
  return await axios.post(`/api/nice/v2/niceDecryptVal`, {
    encryptString
  });
};

/**
 * @description Social 회원 본인인증 정보 업데이트 V2
 * @param {*} encryptString
 * @param {*} userId
 * @param {*} deviceToken
 * @param {*} deviceOs
 * @returns Promise
 */
const updateUserAuth = async (encryptString, userId, deviceToken, deviceOs) => {
  return await axios.post(`/api/social/v2/updateUserAuth`, {
    encryptString,
    userId,
    deviceToken,
    deviceOs
  });
};

/**
 * @description 전화번호로 검색하여 기존 가입 이메일 정보 체크 V2
 * @param {*} encryptString
 * @returns Promise
 */
const findOldSocialInfoUseMobileNo = async (encryptString = '') => {
  return await axios.post(`/api/social/v2/findOldSocialInfoUseMobileNo`, {
    encryptString
  });
};

export {
  getUserCheck,
  niceDecrptVal,
  updateUserAuth,
  findOldSocialInfoUseMobileNo
};
